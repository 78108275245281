import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useParams, useLocation, useNavigate } from 'react-router-dom';

import LegendaryHypeServicesLogo from "../HUDContent/HUDHypeUI/LegendaryHypeServicesLogo.png";

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeHUDListSubscribedHypeServicesCell from "../HUDComponents/HypeHUDCells/HypeHUDListSubscribedHypeServicesCell.js";

import HypeMediaShowcaseList from "../HUDComponents/MediaShowcaseList.js";

import HypeSidePanel from "../HUDComponents/HypeSidePanel.js";

import HypeHUDListCell from "../HUDComponents/HypeHUDListCell.js";

import HypeHUDList from "../HUDComponents/HypeHUDList.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











function HypeServicesEnterpriseViewerPanel({ signedIn })
{
     const [backButtonHovered, setBackButtonHovered] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(true);
     
     const sidePanelRef = useRef();
     
     const currentLocation = useLocation();
     
     const navigate = useNavigate();
     
     const { navigationID } = useParams();
     
     const currentEnterpriseProfileData = currentLocation.state?.enterpriseItemData || {};
     
     const employeeHoverProfilePic = require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileButtonHUDActivatedIcon.png");
     
     const employeeProfilePic = require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileButtonHUDIcon.png");
     
     const backButtonHoveredIcon = require("../HUDContent/HUDHypeUI/HypeTrainWarpHUDBackButtonActivatedIcon.png");
     
     const backButtonIcon = require("../HUDContent/HUDHypeUI/HypeTrainWarpHUDBackButtonIcon.png");
     
     var panelTitle = "";
     
     const handleMouseMove = (e) => {
          if (sidePanelRef.current !== null && sidePanelRef.current !== undefined)
          {
               setSidePanelVisible(HypeHUDUtilityManager.mouseNearComponent(sidePanelRef, 269, e));
          }
     };
     
     const handleBackButtonAction = () => {
          navigate(-1);
          
          return;
     };
     
     const handleButtonClick = (buttonNavigationID) => {
          return;
     };
     
     const buttons = [
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonIcon.png"), 
            navigationID : "/" 
          },
          /*{ 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDServiceProductsButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDServiceProductsButtonIcon.png"), 
            navigationID : "/products" 
          },*/
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterprisesButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterprisesButtonIcon.png"), 
            navigationID : "/enterprises" 
          },
     ];
     
     const subHeaderButton = {
          hoverImage : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDActivatedIcon.png"),
          image : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDIcon.png"), 
          navigationID : "/authentication",
     };
     
     const addEmployeesOptionHandler = (item) => {
          console.log(currentLocation.pathname);
          
          navigate("/");
          
          return;
     };
     
     const enterpriseViewerOptions = [
          {
               id : 1,
               hoverSource : require("../HUDContent/HUDHypeUI/HypeTrainHUDAddItemButtonActivatedIcon.png"),
               source : require("../HUDContent/HUDHypeUI/HypeTrainHUDAddItemButtonIcon.png"),
               hudAction : addEmployeesOptionHandler,
               type : "image",
               title : "Add Employees",
          }
     ];
     
     const currentEnterpriseEmployees = [
          {
               id : 1,
               hoverSource : employeeHoverProfilePic,
               source : employeeProfilePic,
               type : "image",
               title : "Steven Compton"
          },
          {
               id : 2,
               hoverSource : employeeHoverProfilePic,
               source : employeeProfilePic,
               type : "image",
               title : "Chris Floressantos"
          },
     ];
     
     
     
     
     
     
     
     
     
     
     
     if (currentEnterpriseProfileData !== {})
     {
          panelTitle = currentEnterpriseProfileData.title;
     }
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          <style>
                  {`
                    body {
                      background : linear-gradient(to bottom, #271d29 80%, #ff00ff 287%);
                      margin : 0;
                      padding : 0;
                      min-height : 100vh;
                    }
                  `}
          </style>
          
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                    background : "transparent",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    bottom : "0%"
               }}
               
               onMouseMove = {handleMouseMove}
          >
               <label
                    style = {{
                         position : "absolute",
                         height : "41px",
                         width : "469px",
                         transition : "margin-left 0.69s ease-in-out",
                         fontFamily : "ModeNine",
                         fontWeight : "bold",
                         fontSize : "21.69px",
                         textAlign : "center",
                         color : "white",
                         marginLeft : sidePanelVisible ? "427px" : "369px",
                         marginTop : "69px",
                         zIndex : "27"
                    }}
               >
                    <HypeTyper speed = {21} >
                         {panelTitle ? panelTitle : ""}
                    </HypeTyper>
               </label>
               
               <img
                    style = {{
                         position : "absolute",
                         cursor : "pointer",
                         height : "37px",
                         width : "69px",
                         transition : "margin-left 0.69s ease-in-out",
                         borderRadius : "21px",
                         marginLeft : sidePanelVisible ? "227px" : "21px",
                         marginTop : "69px",
                    }}
                    
                    onMouseLeave = {() => {
                         setBackButtonHovered(false);
                         
                         return;
                    }}
                    
                    onMouseEnter = {() => {
                         setBackButtonHovered(true);
                         
                         return;
                    }}
                    
                    onClick = {handleBackButtonAction}
                    
                    src = {backButtonHovered ? backButtonHoveredIcon : backButtonIcon}
               >
               
               </img>
               
               
               
               
               
               <HypeHUDList
                    sidePanelVisible = {sidePanelVisible}
               >
                    <HypeHUDListSubscribedHypeServicesCell
                         listData = {[
                              {
                                   serviceTitle : "Trap-S",
                                   serviceDescription : "Provides absolute security for your data, ensuring no hacker could crack into your network.",
                                   serviceSubscriptionStatus : "Subscribed",
                                   serviceShowcaseType : "image",
                                   source : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainMultiversalCorpIcon.png"),
                              },
                         ]}
                         
                         cellTitleConfigurationStyle = {{
                              color : "white"
                         }}
                         
                         cellLogoHeaderVisible = {true}
                         
                         cellLogo = {require("../HUDContent/HUDHypeUI/HypeTrainHUDHypeServiceIcon.png")}
                         
                         cellTitle = "Subscribed Hype Services"
                    >
                         
                    </HypeHUDListSubscribedHypeServicesCell>
                    
                    <HypeHUDListCell
                         sidePanelVisible = {sidePanelVisible}
                    >
                         <label
                              style = {{
                                   position : "absolute",
                                   color : "rgba(176, 169, 187, 1)",
                                   textShadow : "0 0 10px rgba(255, 255, 255, 0.369)",
                                   width : "50%",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   fontStyle : "italic",
                                   textAlign : "center",
                                   marginLeft : "187px",
                                   marginTop : "15px",
                              }}
                         >
                              <HypeTyper speed = {21} >
                                   Registered API Keys
                              </HypeTyper>
                         </label>
                    </HypeHUDListCell>
                    
                    <HypeHUDListCell
                         sidePanelVisible = {sidePanelVisible}
                    >
                         <label
                              style = {{
                                   position : "absolute",
                                   color : "rgba(176, 169, 187, 1)",
                                   textShadow : "0 0 10px rgba(255, 255, 255, 0.369)",
                                   width : "50%",
                                   fontFamily : "ModeNine",
                                   fontWeight : "bold",
                                   fontStyle : "italic",
                                   textAlign : "center",
                                   marginLeft : "187px",
                                   marginTop : "15px",
                              }}
                         >
                              <HypeTyper speed = {21} >
                                   Employee Positions
                              </HypeTyper>
                         </label>
                    </HypeHUDListCell>
               </HypeHUDList>
               
               
               
               
               
               <HypeMediaShowcaseList
                    gridConfigurationStyle = {{
                         background : "rgba(0, 0, 0, 0.269)",
                         flexDirection: "row-reverse",
                         minHeight : "2vh",
                         height : "21px",
                         width : "369px",
                         borderRadius : "0px 0px 15px 15px",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         transition : "box-shadow 1.769s ease-in-out, margin-left 0.69s ease-in-out",
                         overflowY : "hidden",
                         marginLeft : "64%",
                         zIndex : "1"
                    }}
                    
                    listHeaderTitleConfigurationStyle = {{
                         position : "absolute",
                         fontFamily : "ModeNine",
                         fontWeight : "bold",
                         fontSize : "14.69px",
                         color : "#a3a3a3",
                         transition : "margin-left 0.7169s ease-in-out",
                         marginRight : "0.769%",
                         marginTop : "0%",
                         zIndex : "1",
                    }}
                    
                    titleConfigurationStyle = {{
                         color : "white",
                         fontSize : "12px",
                         marginTop : "869%",
                    }}
                    
                    mediaConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    cellConfigurationStyle = {{
                         background : "transparent",
                         overflow : "visible",
                         cursor : "pointer",
                         height : "37px",
                         width : "37px",
                         marginTop : "-5%",
                    }}
                    
                    overlayConfigurationStyle = {{
                         background : "transparent",
                    }}
                    
                    showcaseItems = {enterpriseViewerOptions}
                    
                    startColor = {[0, 0, 0, 1]}
                    trailColor = {[0, 0, 0, 1]}
                    startHoverColor = {[0, 0, 0, 1]}
                    trailHoverColor = {[0, 0, 0, 1]}
               >
                    
               </HypeMediaShowcaseList>
               
               <HypeMediaShowcaseList
                    gridConfigurationStyle = {{
                         background : "rgba(0, 0, 0, 0.269)",
                         flexDirection: "column",
                         width : "176px",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                         transition : "box-shadow 1.769s ease-in-out, margin-left 0.69s ease-in-out",
                         overflowY : "auto",
                         overflowX : "hidden",
                         marginLeft : "1%",
                         zIndex : "1"
                    }}
                    
                    listHeaderTitleConfigurationStyle = {{
                         position : "absolute",
                         fontFamily : "ModeNine",
                         fontWeight : "bold",
                         fontSize : "14.69px",
                         color : "#a3a3a3",
                         transition : "margin-left 0.7169s ease-in-out",
                         marginLeft : "1.269%",
                         marginTop : "1%",
                         zIndex : "1",
                    }}
                    
                    titleConfigurationStyle = {{
                         color : "white",
                         fontSize : "15.69px",
                         marginTop : "150%"
                    }}
                    
                    mediaDescriptorConfigurationStyle = {{
                         color : "rgba(247, 247, 247, 1)",
                         width : "115px",
                         fontFamily : "ModeNine",
                         fontStyle : "italic",
                         fontSize : "15px",
                         marginTop : "100px",
                    }}
                    
                    mediaConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    cellConfigurationStyle = {{
                         background : "transparent",
                         height : "115px",
                         width : "87px",
                         cursor : "pointer",
                         marginLeft : "7px",
                         marginTop : "3px",
                    }}
                    
                    overlayConfigurationStyle = {{
                         background : "transparent"
                    }}
                    
                    showcaseItems = {currentEnterpriseEmployees}
                    
                    mediaDescriptorLabel = "No employees yet..."
                    
                    listHeaderTitle = "Current Employees"
                    
                    listHeaderVisible = {true}
                    
                    startColor = {[0, 0, 0, 1]}
                    trailColor = {[0, 0, 0, 1]}
                    startHoverColor = {[0, 0, 0, 1]}
                    trailHoverColor = {[0, 0, 0, 1]}
               >
                    
               </HypeMediaShowcaseList>
               
               <HypeSidePanel
                    ref = {sidePanelRef}
                    
                    headerLogo = {LegendaryHypeServicesLogo}
                    
                    header = "Enterprises"
                    
                    subHeader = "Sign In"
                    
                    subHeaderButton = {subHeaderButton}
                    
                    buttons = {buttons}
                    
                    onButtonClick = {handleButtonClick}
                    
                    pageIdentifier = {true}
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
                    
                    authenticated = {true}
                    
                    subHeaderButtonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    subHeaderButtonConfigurationStyle = {{
                         height : "127px",
                         width : "37%",
                         marginLeft : "57px",
                         marginTop : "47px",
                         background : "transparent",
                         fontFamily : "ModeNine",
                         cursor : "pointer",
                    }}
                    
                    sidePanelConfigurationStyle = {{
                         backgroundColor : "rgba(29, 23, 31, 0.569)",
                         width : "187.69px",
                         zIndex : "1699"
                    }}
                    
                    subHeaderConfigurationStyle = {{
                         fontFamily : "ModeNine",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.269)",
                         background : "rgba(15, 15, 15, 0.469)",
                         height : "169px"
                    }}
                    
                    headerConfigurationStyle = {{
                         marginBottom : "5%",
                         marginLeft : "3%",
                    }}
                    
                    buttonListConfigurationStyle = {{
                         marginLeft : "12.69%",
                         marginTop : "7%",
                         height : "calc(69% - 115.69px)",
                    }}
                    
                    buttonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    buttonConfigurationStyle = {{
                         backgroundColor : "transparent",
                         fontFamily : "ModeNine",
                         borderRadius : "8px",
                         height : "145px",
                         width : "103.69px",
                         cursor : "pointer",
                         marginBottom : "15%",
                         marginLeft : "14.69%",
                         marginTop : "5%",
                    }}
                    
                    //footer = {true}
                    
                    footerButtonHoverIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonActivatedIcon.png")}
                    
                    footerButtonIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonIcon.png")}
               >
               
               </HypeSidePanel>
          </div>
          </>
     );
}

export default HypeServicesEnterpriseViewerPanel;