import * as React from "react";

import axios from "axios";










var HypeHUDUtilityManager = {
     executeAction : function(desiredAction, desiredActionInput)
     {
          if (desiredAction !== undefined)
          {
               desiredAction(desiredActionInput);
               
               return;
          }
          
          return;
     },
     
     mouseNearComponent : function(component, appearanceThreshold, e)
     {
         const componentRect = component.current.getBoundingClientRect();
         
         const mouseX = e.clientX;
         
         const mouseY = e.clientY;
         
         const distanceThreshold = appearanceThreshold ? appearanceThreshold : 69;
         
         const mouseNearby = (
              mouseX >= componentRect.left - distanceThreshold &&
              mouseX <= componentRect.right + distanceThreshold &&
              mouseY >= componentRect.top - distanceThreshold &&
              mouseY <= componentRect.bottom + distanceThreshold
         );
         
         return mouseNearby;
     },
     
     nullOrWhitespace : function(inputString)
     {
         return inputString === null || inputString === undefined || inputString === "" || /^\s*$/.test(inputString);
     },
     
     setScrollPosition : function(document, elementId, position) {
         const element = document.getElementById(elementId);
         
         if (element) {
              element.scrollTop = position;
              
              return;
         }
         
         return;
     },
     
     scrollToElement : function(document, elementId) {
         const element = document.getElementById(elementId);
         
         if (element) 
         {
              element.scrollIntoView({ behavior: 'smooth' });
              
              return;
         }
         
         return;
     },
     
     
     
     
     getHypeUser : async function(username)
     {
         try
         {
              const response = await axios({
                   url: "https://api.hypeservices.hypetrain.co/api/hypeuser",
                   method: "GET",
                   params: {
                        "inputUsername": username
                   },
                   responseType: "json",
                   timeout: 5000,
              });
              
              return;
         }
         catch (error)
         {
              return;
         }
     },
     
     signUpHypeUser : async function(firstName, middleName, lastName, displayName, username, email, password)
     {
         try
         {
              const response = await axios({
                   url: "https://api.hypeservices.hypetrain.co/api/signup",
                   method: "POST",
                   params: {
                        "firstName" : firstName,
                        "middleName" : middleName,
                        "lastName" : lastName,
                        "displayName" : displayName,
                        "username": username,
                        "email" : email,
                        "password" : password
                   },
                   responseType: "arraybuffer",
                   timeout: 5000,
              });
              
              if (response.status >= 200 && response.status < 300)
              {
                   return true;
              }
              
              return false;
         }
         catch (error)
         {
              return false;
         }
     },
     
     signInHypeUser : async function(username, password)
     {
         try
         {
              const response = await axios({
                   url: "https://api.hypeservices.hypetrain.co/api/signin",
                   method: "POST",
                   params: {
                        "username": username,
                        "password" : password
                   },
                   responseType: "arraybuffer",
                   timeout: 5000,
              });
              
              if (response.status >= 200 && response.status < 300)
              {
              return true;
              }
              
              return false;
         }
         catch (error)
         {
              return false;
         }
     }
}

export default HypeHUDUtilityManager;