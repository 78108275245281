import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import LegendaryHypeServicesLogo from "../HUDContent/HUDHypeUI/LegendaryHypeServicesLogo.png";

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeSidePanel from "../HUDComponents/HypeSidePanel.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











function HypeServicesHomePanel({ signedIn })
{
     const [hypeServicesTitleIlluminationLoaded, setHypeServicesTitleIlluminationLoaded] = useState(false);
     
     const [hypeServicesTitleLoaded, setHypeServicesTitleLoaded] = useState(false);
     
     const [hypeServicesLogoLoaded, setHypeServicesLogoLoaded] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(true);
     
     const sidePanelRef = useRef();
     
     const handleMouseMove = (e) => {
          if (sidePanelRef.current !== null && sidePanelRef.current !== undefined)
          {
               setSidePanelVisible(HypeHUDUtilityManager.mouseNearComponent(sidePanelRef, 269, e));
          }
     };
     
     const handleButtonClick = (buttonNavigationID) => {
          return;
     };
     
     const buttons = [
          /*{ 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDServiceProductsButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDServiceProductsButtonIcon.png"), 
            navigationID : "/products" 
          },*/
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterprisesButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterprisesButtonIcon.png"), 
            navigationID : "/enterprises" 
          },
          /*{ 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDHypeServiceManagementButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDHypeServiceManagementButtonIcon.png"), 
            navigationID : "/servicemanagement" 
          },
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHypeServiceConfigurationHUDButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHypeServiceConfigurationHUDButtonIcon.png"), 
            navigationID : "/serviceconfiguration" 
          },*/
     ];
     
     const subHeaderButton = {
          hoverImage : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDActivatedIcon.png"),
          image : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDIcon.png"), 
          navigationID : "/authentication",
     };
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          const hypeServicesTitleIlluminationLoading = setTimeout(() => {
               setHypeServicesTitleIlluminationLoaded(true);
          }, 2569);
          
          const hypeServicesTitleLoading = setTimeout(() => {
               setHypeServicesTitleLoaded(true);
          }, 699);
          
          const hypeServicesLogoLoading = setTimeout(() => {
               setHypeServicesLogoLoaded(true);
          }, 1169.69);
          
          
          
          return () => {
               clearTimeout(hypeServicesTitleIlluminationLoading);
               
               clearTimeout(hypeServicesTitleLoading);
               
               clearTimeout(hypeServicesLogoLoading);
          };
     }, []);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          <style>
                  {`
                    body {
                      background : linear-gradient(to bottom, #271d29 80%, #ff00ff 287%);
                      margin : 0;
                      padding : 0;
                      min-height : 100vh;
                    }
                  `}
          </style>
          
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    background : "transparent",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    bottom : "0%"
               }}
               onMouseMove = {handleMouseMove}
          >
               <form
                    style = {{
                         position : "absolute",
                         background : "transparent",
                         transition : "margin-top 1.269s ease-in-out",
                         outline : "none",
                         border : "none",
                         height : "calc(195.69px)",
                         width : "calc(269px)",
                         borderRadius : "15px",
                         marginLeft : "39%",
                         marginTop : hypeServicesLogoLoaded ? "57px" : "-27%"
                    }}
               >
               <img
                    style = {{
                         position : "absolute",
                         background : "transparent",
                         transition : "margin-top 1.269s ease-in-out",
                         outline : "none",
                         border : "none",
                         height : "calc(137px)",
                         width : "calc(147px)",
                         borderRadius : "7px",
                         marginLeft : "23%",
                         marginTop : hypeServicesLogoLoaded ? "0%" : "-27%"
                    }}
                    
                    alt = "Hype Services"
                    
                    src = {LegendaryHypeServicesLogo}
               >
                    
               </img>
               
               <label
                    style = {{
                         position : "absolute",
                         backgroundClip : "text",
                         background : "linear-gradient(74deg, #ecb8ff 0%, #b826ed 227%)",
                         WebkitBackgroundClip : "text",
                         transition : "text-shadow 0.269s ease-in, margin-top 1.269s ease-in-out",
                         textShadow : hypeServicesTitleIlluminationLoaded ? "0 0 12.69px rgba(253, 222, 255, 0.8)" : "none",
                         textFillColor : "transparent",
                         outline : "none",
                         border : "none",
                         fontFamily : "ModeNine",
                         fontWeight : "bold",
                         fontSize : "169%",
                         textAlign : "center",
                         color : "transparent",
                         height : "calc(37px)",
                         width : "100%",
                         borderRadius : "7px",
                         marginLeft : "0px",
                         marginTop : hypeServicesTitleLoaded ? "157px" : "-15%"
                    }}
               >
                    <HypeTyper speed = {137} >
                         Hype Services
                    </HypeTyper>
               </label>
               </form>
               
               <HypeSidePanel
                    ref = {sidePanelRef}
                    headerLogo = {LegendaryHypeServicesLogo}
                    header = "Home"
                    subHeader = "Sign In"
                    subHeaderButton = {subHeaderButton}
                    buttons = {buttons}
                    onButtonClick = {handleButtonClick}
                    pageIdentifier = {true}
                    setVisible = {setSidePanelVisible}
                    visible = {sidePanelVisible}
                    authenticated = {true}
                    subHeaderButtonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    subHeaderButtonConfigurationStyle = {{
                         height : "127px",
                         width : "37%",
                         marginLeft : "57px",
                         marginTop : "47px",
                         background : "transparent",
                         fontFamily : "ModeNine",
                         cursor : "pointer",
                    }}
                    sidePanelConfigurationStyle = {{
                         backgroundColor : "rgba(29, 23, 31, 0.569)",
                         width : "187.69px",
                         zIndex : "1699"
                    }}
                    subHeaderConfigurationStyle = {{
                         fontFamily : "ModeNine",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.269)",
                         background : "rgba(15, 15, 15, 0.469)",
                         height : "169px"
                    }}
                    headerConfigurationStyle = {{
                         marginBottom : "5%",
                         marginLeft : "3%",
                    }}
                    buttonListConfigurationStyle = {{
                         marginLeft : "12.69%",
                         marginTop : "7%",
                         height : "calc(69% - 115.69px)",
                    }}
                    buttonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    buttonConfigurationStyle = {{
                         backgroundColor : "transparent",
                         fontFamily : "ModeNine",
                         borderRadius : "8px",
                         height : "145px",
                         width : "103.69px",
                         cursor : "pointer",
                         marginBottom : "15%",
                         marginLeft : "14.69%",
                         marginTop : "5%",
                    }}
                    
                    //footer = {true}
                    
                    footerButtonHoverIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonActivatedIcon.png")}
                    
                    footerButtonIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonIcon.png")}
                    
               >
               
               </HypeSidePanel>
          </div>
          </>
     );
}

export default HypeServicesHomePanel;