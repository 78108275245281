import * as React from "react";

import HypeTyper from "../HUDComponents/HypeTyper.js";











function HypeLogoComponent({ children, configurationSettings, logoConfigurationSettings, mottoConfigurationSettings, darkMode, logo, darkModeLogo, mottoVisible })
{
     var hypeLogo = logo;
     
     if (darkMode && darkModeLogo !== undefined)
     {
          hypeLogo = darkModeLogo;
     }
     
     return(
          <div
               style = {configurationSettings}
          >
               <img
                    style = {{
                         background : "transparent",
                         position : "fixed",
                         height : "57px",
                         width : "47px",
                         borderRadius : "15px",
                         objectFit : "contain",
                         aspectRatio : "1",
                         marginLeft : "1%",
                         marginTop : "0.269%",
                         top : "0%",
                         ...logoConfigurationSettings
                    }}
                    
                    src = {hypeLogo}
                    
                    alt = ""
               >
                      
               </img>
               
               <label
                    style = {{
                         background : "transparent",
                         position : "fixed",
                         height : "21px",
                         width : "69px",
                         fontWeight : "bold",
                         fontSize : "16.369px",
                         borderRadius : "50px",
                         visibility : mottoVisible ? "visible" : "hidden",
                         transition : "color 1.5s ease, WebkitTextStroke 1.5s ease-in",
                         color : darkMode ? "white" : "black",
                         WebkitTextStroke: darkMode ? "none" : "0.169px rgba(255, 255, 255, 1)",
                         objectFit : "contain",
                         aspectRatio : "1",
                         marginLeft : "5.69%",
                         marginTop : "0.869%",
                         top : "0%",
                         ...mottoConfigurationSettings
                    }}
               >
                    <HypeTyper speed = {41} >
                         {mottoVisible ? children : ""}
                    </HypeTyper>
               </label>
          </div>
     );
}

export default HypeLogoComponent;