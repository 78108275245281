import React from "react";

import { useEffect, useState, useRef } from "react";

import HypeScrollerObserver from "../HUDComponents/HypeScrollerObserver.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











const MediaShowcaseList = ({
  showcaseItems,
  gridConfigurationStyle,
  cellConfigurationStyle,
  mediaConfigurationStyle,
  contentConfigurationStyle,
  titleConfigurationStyle,
  listHeaderTitleConfigurationStyle,
  descriptionConfigurationStyle,
  listHeaderConfigurationStyle,
  overlayConfigurationStyle,
  mediaDescriptorConfigurationStyle,
  mediaDescriptorLabel,
  listHeaderVisible,
  listHeaderTitle,
  listID,
  onItemClicked,
  startColor, 
  trailColor, 
  startHoverColor, 
  trailHoverColor
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  
  const contentRefs = useRef([]);
  
  const listHeaderTitleStyle = {
    position : "absolute",
    fontFamily : "ModeNine",
    fontWeight : "bold",
    fontSize : "14.69px",
    color : "#a3a3a3",
    transition : "margin-left 0.7169s ease-in-out",
    wordWrap : "break-word",
    marginLeft : "3.69%",
    marginTop : "1%",
    zIndex : "1",
    ...listHeaderTitleConfigurationStyle
  };
  
  const listHeaderStyle = {
    background : "transparent",
    height : "47px",
    width : "100%",
    marginTop : "18.69%",
    ...listHeaderConfigurationStyle
  };
  
  const listStyle = {
    display: "flex",
    gap: "20px",
    padding: "20px",
    overflowX: "auto",
    ...gridConfigurationStyle
  };

  const cellStyle = {
    position: "relative",
    borderRadius: "15px",
    overflow: "hidden",
    cursor : "pointer",
    flexShrink: 0,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out",
    ...cellConfigurationStyle
  };
  
  const mediaStyle = {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    ...mediaConfigurationStyle
  };
  
  const contentStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px",
    opacity: "0",
    transition: "opacity 0.3s ease",
    ...contentConfigurationStyle
  };

  const overlayStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.69)",
    zIndex: "1",
    ...overlayConfigurationStyle
  };

  const titleStyle = {
    ...titleConfigurationStyle
  };

  const descriptionStyle = {
    ...descriptionConfigurationStyle
  };
  
  const handleCellHover = (index, hovered) => {
    if (contentRefs.current[index]) {
      contentRefs.current[index].style.opacity = hovered ? "1" : "0";
    }
    
    setHoveredIndex(hovered ? index : null);
  };
  
  
  
  
  
  useEffect(() => {
       HypeScrollerObserver(document, startColor, trailColor, startHoverColor, trailHoverColor);
       
       return;
  }, [document, startColor, trailColor, startHoverColor, trailHoverColor]);
  
  if (mediaDescriptorLabel === null || mediaDescriptorLabel === undefined)
  {
       mediaDescriptorLabel = "No media yet...";
  }
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div id = {listID} className = "hype-scroller" style={listStyle}>
      {listHeaderVisible && (
          <div
               style = {listHeaderStyle}
          >
               <label
                    style = {listHeaderTitleStyle}
               >
                    <HypeTyper speed = {21} >
                         {listHeaderTitle}
                    </HypeTyper>
               </label>
          </div>
      )}
      
      {showcaseItems && showcaseItems.length > 0 ? (
        showcaseItems.map((item, index) => (
          <div
            key = {index}
            
            style = {{
                 ...cellStyle,
                 transform : `scale(${hoveredIndex === index ? 1.05 : 1})`,
            }}
            
            onMouseEnter = {() => handleCellHover(index, true)}
            
            onMouseLeave = {() => handleCellHover(index, false)}
            
            onClick = {() => {
                if (item.hudAction) { item.hudAction(item); }
                
                if (onItemClicked) { onItemClicked(item); }
                
                return;
            }}
          >
            {item.type === "image" ? (
              <img 
                src = {
                     hoveredIndex === index
                          ? item.hoverSource || item.source
                          : item.source
                } 
                alt = {item.title} 
                style = {mediaStyle} 
              />
            ) : (
              <video
                src={item.source}
                alt={item.title}
                style={mediaStyle}
                autoPlay
                loop
                muted
                playsInline
              />
            )}

            <div ref = {(el) => (contentRefs.current[index] = el)} style={contentStyle}>
              <div style={overlayStyle}></div>
              <h3 style={titleStyle}>{item.title}</h3>
              <p style={descriptionStyle}>{item.description}</p>
            </div>
          </div>
        ))
      ) : (
        <label
          style={{
            position: "absolute",
            background: "transparent",
            color: "black",
            transition: "font-size 0.469s ease",
            fontWeight: "bolder",
            fontSize: "37.69px",
            textAlign: "center",
            width: "100%",
            ...mediaDescriptorConfigurationStyle
          }}
        >
          <HypeTyper speed={21}>{mediaDescriptorLabel}</HypeTyper>
        </label>
      )}
    </div>
  );
};

export default MediaShowcaseList;
