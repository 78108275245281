import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';











const HypeNavigationComponent = ({ children }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return children(navigateTo);
};

export default HypeNavigationComponent;