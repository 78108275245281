import React from "react";










const HypeHUDListCell = ({ children, cellConfigurationStyle, sidePanelVisible }) => {
  return (
       <div
            className = "hype-scroller"
            
            style = {{
                 position : "relative",
                 boxShadow : "0 0 10px rgba(0, 0, 0, 0.469)",
                 background : "rgba(37, 37, 37, 0.269)",
                 height : "369px",
                 width : "87%",
                 borderRadius : "21px",
                 transition : "margin-left 0.69s ease-in-out",
                 marginLeft : sidePanelVisible ? "37px" : "57px",
                 marginTop : "37px",
                 ...cellConfigurationStyle
            }}
       >
            {children}
       </div>
  );
};

export default HypeHUDListCell;
