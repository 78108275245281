import * as React from "react";

import { useEffect } from "react";

import HypeScrollerObserver from "../HUDComponents/HypeScrollerObserver.js";










const HypeHUDList = ({ children, listConfigurationStyle, sidePanelVisible }) => {
  useEffect(() => {
       HypeScrollerObserver(document, [69, 69, 69, 0], [224, 133, 255, 0], [171, 157, 176, 0], [242, 204, 255, 0]);
       
       return () => { };
  }, [document]);
  
  
  
  
  
  
  
  
  
  
  
  return (
       <div
            className = "hype-scroller"
            
            style = {{
                 position : "absolute",
                 display : "flex",
                 flexDirection : "column",
                 boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                 background : "rgba(15, 15, 15, 0.269)",
                 height : "469px",
                 width : "69%",
                 borderRadius : "21px 21px 0px 0px",
                 transition : "margin-left 0.69s ease-in-out",
                 marginLeft : sidePanelVisible ? "215px" : "137px",
                 overflowX : "hidden",
                 overflowY : "auto",
                 marginTop : "120px",
                 gap : "10px",
                 ...listConfigurationStyle
            }}
       >
            <ul style={{ listStyle: "none", padding: 0 }}>
                 {React.Children.map(children, (child, index) => (
                      <li key = {index} style = {{ marginBottom: "10px" }}>
                           {child}
                      </li>
                 ))}
            </ul>
       </div>
  );
};

export default HypeHUDList;
