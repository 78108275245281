import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();











export const HypeAuthProvider = ({ children }) => {
  const [signedIn, setSignedIn] = useState(false);
  
  
  
  
  
  
  
  
  
  
  
  const hypeSignIn = () => {
    setSignedIn(true);
  };
  
  const hypeSignOut = () => {
    setSignedIn(false);
  };
  
  
  
  
  
  
  
  
  
  
  
  
  return (
    <AuthContext.Provider value={{ signedIn, hypeSignIn, hypeSignOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useHypeAuth = () => {
  const context = useContext(AuthContext);
  
  if (!context) 
  {
       throw new Error('Must use within an HypeAuthProvider');
  }
  
  return context;
};
