import React from "react";

import { useState, useRef } from "react";

import { useNavigate } from "react-router-dom";

import HypeHUDListCell from "../../HUDComponents/HypeHUDListCell.js";

import HypeHUDList from "../../HUDComponents/HypeHUDList.js";

import HypeTyper from "../../HUDComponents/HypeTyper.js";










const HypeHUDListSubscribedHypeServicesCell = ({ 
  listData, 
  cellLogo, 
  cellLogoHeaderVisible, 
  cellConfigurationStyle, 
  cellTitle, 
  cellTitleConfigurationStyle, 
  cellDescriptionConfigurationStyle,
  cellContentConfigurationStyle, 
  cellMediaCofigurationStyle,
  overlayConfigurationStyle, 
  subscribedButtonHoverColor,
  subscribedButtonColor,
  subscribeButtonHoverColor,
  subscribeButtonColor,
  onItemClicked,
  hypeServices, 
  sidePanelVisible 
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  
  const [actionButtonHovered, setActionButtonHovered] = useState(false);
  
  const [currentHypeServiceSubscriptionDetails, setCurrentHypeServiceSubscriptionDetails] = useState(null);
  
  const contentRefs = useRef([]);
  
  const navigate = useNavigate();
  
  const overlayStyle = {
    position : "absolute",
    top : "0",
    left : "0",
    right : "0",
    bottom : "0",
    transition : "box-shadow 0.69s ease-in-out",
    boxShadow : "inset 0 0 10px rgba(187, 0, 137, 0.569)",
    borderRadius : "21px",
    background : "rgba(0, 0, 0, 0.569)",
    zIndex : "1",
    ...overlayConfigurationStyle
  };
  
  const cellMediaStyle = {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    ...cellMediaCofigurationStyle
  };
  
  const contentStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px",
    opacity: "0",
    transition: "opacity 0.3s ease",
    ...cellContentConfigurationStyle
  };
  
  const titleStyle = {
    position : "absolute",
    zIndex : "15",
    width : "100%",
    transition : "margin-top 0.469s ease-in-out",
    textAlign : "center",
    fontSize : "15.69px",
    marginBottom : "0%",
    ...cellTitleConfigurationStyle
  };

  const descriptionStyle = {
    zIndex : "15",
    ...cellDescriptionConfigurationStyle
  };
  
  const handleCellHover = (index, hovered) => {
    if (contentRefs.current[index]) {
      contentRefs.current[index].style.opacity = hovered ? "1" : "0";
    }
    
    setHoveredIndex(hovered ? index : null);
  };
  
  if (subscribedButtonHoverColor === undefined || subscribedButtonHoverColor === null)
  {
       subscribedButtonHoverColor = "linear-gradient(77deg, rgba(187, 27, 187, 1) 0%, rgba(227, 27, 227, 1) 150%)";
  }
  
  if (subscribedButtonColor === undefined || subscribedButtonColor === null)
  {
       subscribedButtonColor = "linear-gradient(77deg, rgba(227, 27, 227, 0.269) 0%, rgba(187, 27, 187, 0.269) 69%)";
  }
  
  
  
  
  
  
  
  
  
  
  
  return (
       <HypeHUDListCell
            sidePanelVisible = {sidePanelVisible}
            
            cellConfiguration = {{...cellConfigurationStyle, marginTop : "0" }}
       >
            <label
                 style = {{
                      position : "absolute",
                      color : "rgba(176, 169, 187, 1)",
                      textShadow : "0 0 10px rgba(255, 255, 255, 0.369)",
                      width : "50%",
                      fontFamily : "ModeNine",
                      fontWeight : "bold",
                      fontStyle : "italic",
                      textAlign : "center",
                      marginLeft : "187px",
                      marginTop : "15px",
                 }}
            >
                 <HypeTyper speed = {21} >
                      {cellTitle ? cellTitle : ""}
                 </HypeTyper>
            </label>
            
            <HypeHUDList
                 listConfigurationStyle = {{
                      height : "69%",
                      width : "23%",
                      marginLeft : currentHypeServiceSubscriptionDetails ? "21px" : "100px",
                      marginTop : "57px",
                 }}
            >
                 {listData && listData.length > 0 ? listData.map((item, index) => (
                      <div
                           key = {index}
                           
                           style = {{
                                position : "relative",
                                boxShadow : hoveredIndex === index ? "0 0 15.69px rgba(0, 0, 0, 0.569)" : "none",
                                borderRadius : "21px",
                                transition : "box-shadow 0.469s ease-in-out, background 0.469s ease-in-out",
                                background : hoveredIndex === index ? "rgba(100, 0, 127, 0.569)" : "transparent",
                                height : "127px",
                                width : "127px",
                                marginTop : "15px",
                                marginLeft : "21px",
                           }}
                           
                           onMouseEnter = {() => handleCellHover(index, true)}
                           
                           onMouseLeave = {() => handleCellHover(index, false)}
                           
                           onClick = {() => {
                                if (item.hudAction)
                                {
                                     item.hudAction(item);
                                }
                                
                                if (onItemClicked)
                                {
                                     onItemClicked(item);
                                }
                                
                                setCurrentHypeServiceSubscriptionDetails(item);
                                
                                return;
                           }}
                      >
                           {cellLogoHeaderVisible && (
                                 <img
                                     style = {{
                                          position : "absolute",
                                          borderRadius : "3px",
                                          background : "transparent",
                                          height : "76px",
                                          width : "57px",
                                          marginLeft : "31px",
                                          transition : "opacity 0.469s ease-in-out",
                                          opacity : hoveredIndex === index ? "0" : "1",
                                          marginTop : "5px",
                                          zIndex : "2",
                                     }}
                                     
                                     src = {cellLogo}
                                >
                                
                                </img>
                           )}
                           
                           {hoveredIndex === index && item.serviceShowcaseType === "image" ? (
                                <img 
                                      src = {
                                           hoveredIndex === index
                                                ? item.hoverSource || item.source
                                                : item.source
                                      } 
                                      
                                      alt = {item.serviceTitle} 
                                      
                                      style = {{...cellMediaStyle, position : "absolute", transition : "opacity 0.769s ease-in-out", opacity : hoveredIndex === index ? "1" : "0"}} 
                                />
                            ) : hoveredIndex === index && item.serviceShowcaseType === "video" ? (
                                 <video
                                      src = {item.source}
                                      alt = {item.serviceTitle}
                                      style = {{...cellMediaStyle, position : "absolute", transition : "opacity 0.769s ease-in-out", opacity : hoveredIndex === index ? "1" : "0"}}
                                      autoPlay
                                      loop
                                      muted
                                      playsInline
                                 />
                           ) : (
                                 <>
                                 </>
                           )}
                           
                           <div ref = {(el) => (contentRefs.current[index] = el)} style = {contentStyle}>
                                <div style = {overlayStyle} ></div>
                           </div>
                           
                           <h3 style = {{...titleStyle, marginTop : hoveredIndex === index ? "15px" : "87px",}} >{item.serviceTitle ? item.serviceTitle : ""}</h3>
                      </div>
                 )) : (
                      <>
                      <label
                           style = {{
                                position : "absolute",
                                color : "rgba(237, 237, 237, 1)",
                                fontFamily : "ModeNine",
                                fontStyle : "italic",
                                textAlign : "center",
                                marginTop : "21px",
                           }}
                      >
                           <HypeTyper speed = {21} >
                                No subscriptions yet...
                           </HypeTyper>
                      </label>
                      </>
                 )}
            </HypeHUDList>
            
            {!listData || listData.length <= 0 ? (
                 <button
                      style = {{
                           position : "absolute",
                           height : "47px",
                           width : actionButtonHovered ? "147px" : "127px",
                           borderRadius : "50px",
                           fontFamily : "ModeNine",
                           borderTop : "none",
                           boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                           borderBottom : actionButtonHovered ? "solid rgba(169, 0, 187, 1)" : "none",
                           borderRight : actionButtonHovered ? "solid rgba(169, 0, 187, 1)" : "none",
                           borderLeft : "none",
                           fontSize : actionButtonHovered ? "15.769px" : null,
                           scale : actionButtonHovered ? "1.169" : "1",
                           transition : "background 0.69s ease-in-out, border-bottom 0.369s ease-in-out, border-right 0.369s ease-in-out, scale 0.569s ease-in, width 0.69s ease-in-out, font-size 0.369s ease-in",
                           background : actionButtonHovered ? "rgba(227, 27, 227, 0.69)" : "rgba(27, 27, 27, 0.69)",
                           color : "white",
                           marginLeft : "287px",
                           marginTop : "69px",
                      }}
                      
                      onMouseLeave = {() => {
                           setActionButtonHovered(false);
                           
                           return;
                      }}
                      
                      onMouseEnter = {() => {
                           setActionButtonHovered(true);
                           
                           return;
                      }}
                      
                      onClick = {() => {
                           navigate("/products");
                           
                           return;
                      }}
                 >
                      View Services
                 </button>
            ) : ( 
                 <>
                 </> 
            )}
            
            {currentHypeServiceSubscriptionDetails && currentHypeServiceSubscriptionDetails.serviceSubscriptionStatus === "Subscribed" ? (
                 <div
                      className = "hype-scroller"
                      
                      style = {{
                           position : "absolute",
                           height : "269px",
                           width : "527px",
                           borderRadius : "8px",
                           overflowX : "hidden",
                           overflowY : "auto",
                           boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                           background : "rgba(15, 15, 15, 0.69)",
                           color : "white",
                           marginLeft : "215px",
                           marginTop : "57px",
                      }}
                 >
                      <label
                           style = {{
                                position : "absolute",
                                color : "rgba(237, 237, 237, 1)",
                                fontFamily : "ModeNine",
                                fontWeight : "bold",
                                fontStyle : "italic",
                                textAlign : "center",
                                marginLeft : "21px",
                                marginTop : "21px",
                           }}
                      >
                           <HypeTyper speed = {21} >
                                {currentHypeServiceSubscriptionDetails.serviceTitle ? currentHypeServiceSubscriptionDetails.serviceTitle : ""}
                           </HypeTyper>
                      </label>
                      
                      <label
                           style = {{
                                position : "absolute",
                                color : "rgba(237, 237, 237, 1)",
                                fontFamily : "ModeNine",
                                fontWeight : "bold",
                                fontStyle : "italic",
                                textAlign : "center",
                                marginLeft : "21px",
                                marginTop : "69px",
                           }}
                      >
                           <HypeTyper speed = {21} >
                                {currentHypeServiceSubscriptionDetails.serviceDescription ? currentHypeServiceSubscriptionDetails.serviceDescription : ""}
                           </HypeTyper>
                      </label>
                      
                      <button
                      style = {{
                           position : "absolute",
                           height : "47px",
                           width : actionButtonHovered ? "147px" : "127px",
                           borderRadius : "50px",
                           fontFamily : "ModeNine",
                           borderTop : "none",
                           boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.469)",
                           borderBottom : actionButtonHovered ? "solid rgba(169, 0, 187, 1)" : "none",
                           borderRight : actionButtonHovered ? "solid rgba(169, 0, 187, 1)" : "none",
                           borderLeft : "none",
                           fontSize : actionButtonHovered ? "15.769px" : null,
                           scale : actionButtonHovered ? "1.169" : "1",
                           transition : "background 0.69s ease-in-out, border-bottom 0.369s ease-in-out, border-right 0.369s ease-in-out, scale 0.569s ease-in, width 0.69s ease-in-out, font-size 0.369s ease-in",
                           background : actionButtonHovered ? subscribedButtonHoverColor : subscribedButtonColor,
                           color : "white",
                           marginLeft : "21px",
                           marginTop : "187px",
                      }}
                      
                      onMouseLeave = {() => {
                           setActionButtonHovered(false);
                           
                           return;
                      }}
                      
                      onMouseEnter = {() => {
                           setActionButtonHovered(true);
                           
                           return;
                      }}
                      
                      onClick = {() => {
                           
                           
                           return;
                      }}
                 >
                      Subscribed!
                 </button>
                 </div>
            ) : ( 
                 <>
                 </> 
            )}
       </HypeHUDListCell>
  );
};

export default HypeHUDListSubscribedHypeServicesCell;
