import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import '../HUDStyles/HypeAnimations.css';











const HypeBurnTransition = ({ duration, colors, onComplete }) => {
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
      
      onComplete && onComplete();
    }, duration);

    return () => clearTimeout(timeoutId);
  }, [duration, onComplete]);

  const gradient = `linear-gradient(to bottom right, ${colors[0]}, ${colors[1]})`;

  return (
    <div className = {`hype-burn-transition ${visible ? "visible" : "hidden"}`} >
      <div className = "hype-burn-overlay" style={{ background : gradient }} ></div>
    </div>
  );
};

HypeBurnTransition.propTypes = {
  duration: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onComplete: PropTypes.func,
};

export default HypeBurnTransition;
