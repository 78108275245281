import * as React from "react";

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";












function HypeSignInComponent({ sidePanelVisible, hypeSignIn }) {
  const [signInButtonVisibility, setSignInButtonVisibility] = useState("hidden");
  
  const [signInButtonHover, setSignInButtonHover] = useState(false);
  
  const [signInProcessIndicatorTitle, setSignInProcessIndicatorTitle] = useState("Sign In");
  
  const usernameSignInTextFieldRef = useRef();
     
  const passwordSignInTextFieldRef = useRef();
  
  const navigate = useNavigate();
  
  const handleSignInButtonVerification = (e) => {
          if (usernameSignInTextFieldRef.current.value !== "" && passwordSignInTextFieldRef.current.value !== "")
          {
               setSignInButtonVisibility("visible");
               
               return;
          }
          
          setSignInButtonVisibility("hidden");
          
          return;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSignInButtonVisibility(false);
    
    setSignInProcessIndicatorTitle("Signing in...");
    
    HypeHUDUtilityManager.setScrollPosition(document, "SignUpModal", 0);
    
    const signedInHypeUser = await HypeHUDUtilityManager.signInHypeUser(
         usernameSignInTextFieldRef.current.value,
         passwordSignInTextFieldRef.current.value
    );
    
    if (signedInHypeUser) 
    {
         setSignInProcessIndicatorTitle("Signed In!");
         
         hypeSignIn(true);
         
         navigate("/");
    }
    
    setSignInProcessIndicatorTitle("Try Again!");
    
    setTimeout(() => {
         setSignInProcessIndicatorTitle("Sign In");
    }, 2000);
    
    return;
  };
  
  
  
  
  
  
  
  
  
  
  
  return (
       <form
            style = {{
                 position : "absolute",
                 background : "linear-gradient(to bottom, rgba(125, 255, 249, 0.69) 21%, transparent 127%)",
                 height : "469px",
                 width : "469px",
                 boxShadow : "10px 10px 7px -5px rgba(21, 21, 21, 0.69), inset 0 0 10px rgba(255, 255, 255, 0.269)",
                 borderRadius : "21px 69px 21px 69px",
                 transition : "margin-left 0.69s ease-in-out",
                 marginLeft : sidePanelVisible ? "-7.69%" : "-15.69%",
                 marginTop : "5.69%",
            }}
            
            onSubmit = {async () => {
                 setSignInButtonVisibility(false);
                 
                 const signedInHypeUser = await HypeHUDUtilityManager.signInHypeUser(
                      usernameSignInTextFieldRef.current.value,
                      passwordSignInTextFieldRef.current.value
                 );
                 
                 if (signedInHypeUser)
                 {
                      hypeSignIn(true);
                      
                      navigate("/");
                 }
                 
                 return;
            }}
       >
            <label
                 style = {{
                      position : "absolute",
                      fontFamily : "ModeNine",
                      fontWeight : "bold",
                      fontSize : "27.69px",
                      color : "transparent",
                      backgroundClip : "text",
                      background : "linear-gradient(to right, rgba(204, 255, 253, 0.69) 0%, rgba(230, 255, 254, 0.769) 227%)",
                      WebkitBackgroundClip : "text",
                      marginLeft : "33.69%",
                      marginTop : "5%",
                 }}
            >
                 Sign In
            </label>
            
            <input
                 className = "hype-signin-input"
                 
                 ref = {usernameSignInTextFieldRef}
                 
                 style = {{
                      position : "absolute",
                      borderRadius : "50px",
                      height : "27px",
                      width : "287px",
                      fontFamily : "ModeNine",
                      fontSize : "15.69px",
                      marginLeft : "15%",
                      marginTop : "21.69%",
                      borderLeft : "none",
                      borderTop : "none",
                      color : "rgba(230, 255, 254, 1)",
                      background : "transparent",
                      outline : "none",
                      borderBottom : "solid rgba(230, 255, 254, 1)",
                      borderRight : "solid rgba(230, 255, 254, 1)",
                 }}
                 
                 onChange = {handleSignInButtonVerification}
                 
                 placeholder = "Username..."
            >
                 
            </input>
            
            <input
                 className = "hype-signin-input"
                 
                 ref = {passwordSignInTextFieldRef}
                 
                 type = "password"
                 
                 style = {{
                      position : "absolute",
                      borderRadius : "50px",
                      height : "27px",
                      width : "287px",
                      fontFamily : "ModeNine",
                      fontSize : "15.69px",
                      marginLeft : "15%",
                      marginTop : "37.69%",
                      borderLeft : "none",
                      borderTop : "none",
                      color : "rgba(230, 255, 254, 1)",
                      background : "transparent",
                      outline : "none",
                      borderBottom : "solid rgba(230, 255, 254, 1)",
                      borderRight : "solid rgba(230, 255, 254, 1)",
                 }}
                 
                 onChange = {handleSignInButtonVerification}
                 
                 placeholder = "Password..."
            >
                 
            </input>
            
            <button
                 type = "submit"
                 
                 style = {{
                      position : "absolute",
                      background : "linear-gradient(to right, rgba(136, 209, 206, 0.69) 0%, rgba(230, 255, 254, 0.769) 227%)",
                      color : "rgba(230, 255, 254, 0.769)",
                      visibility : signInButtonVisibility,
                      fontFamily : "ModeNine",
                      borderRadius : "50px",
                      fontWeight : "bold",
                      transition : "height 0.69s ease-in, width 0.69s ease-in, font-size 0.69s ease-in",
                      fontSize : signInButtonHover ? "18.69px" : "12.69px",
                      border : "none",
                      boxShadow : signInButtonHover ? "5px 3px 10px -5px rgba(255, 255, 255, 0.469), inset 0 0 10px rgba(255, 255, 255, 0.469)" : "none",
                      height : signInButtonHover ? "57px" : "47px",
                      width : signInButtonHover ? "137px" : "127px",
                      marginLeft : "33%",
                      marginTop : "51%"
                 }}
                 onMouseLeave = {() => {
                      setSignInButtonHover(false);
                      
                      return;
                 }}
                 onMouseEnter = {() => {
                      setSignInButtonHover(true);
                      
                      return;
                 }}
                 onClick = {() => {
                      return;
                 }}
            >
                 Sign In!
            </button>
       </form>
  );
}

export default HypeSignInComponent;