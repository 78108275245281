import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HypeBurnAnimatedNavigator from "./HUDComponents/HypeBurnAnimatedNavigator.js";
import HypeServicesAuthenticationPanel from "./HUDPages/HypeServicesAuthenticationPanel.js";
import HypeServicesEnterprisesPanel from "./HUDPages/HypeServicesEnterprisesPanel.js";
import HypeServicesEnterpriseViewerPanel from "./HUDPages/HypeServicesEnterpriseViewerPanel.js";
import HypeServicesHomePanel from "./HUDPages/HypeServicesHomePanel.js";
import { useHypeAuth } from "./HUDComponents/HypeAuthProvider.js";
import "./HUDStyles/HypeAnimations.css";
import "./HUDStyles/hypeScrollBar.css";
import "./HUDStyles/hudTextInputs.css";
import "./HUDStyles/hudFonts.css";











function App() {
  return (
    <div>
         <noscript>Welcome to Hype Services!</noscript>
         
         <div id="root"></div>
         
         <script type="application/ld+json">
              {`
                   {
                         "@context": "https://schema.org",
                         "@type": "Corporation",
                         "url": "https://hypeservices.hyptrain.co",
                         "name": "Hype Services",
                         "description": "Expanding the capabilities of the cloud by providing the gateway to endless creation.",
                         "logo": "https://hypeservices.hyptrain.co/LegendaryHypeServicesLogo.png"
                   }
             `}
         </script>
         
         <Router>
              <HypeRouter />
         </Router>
    </div>
  );
}

const HypeRouter = () => {
  const hypeBurnTransitionProps = {
    duration : 1269,
    colors : ["rgba(194, 13, 255, 0.8)", "rgba(252, 13, 244, 0.69)"],
  };
  
  const { signedIn, hypeSignIn, hypeSignOut } = useHypeAuth();
  
  const location = useLocation();
  
  
  
  
  
  
  
  
  
  
  
  return (
     <>
          <HypeBurnAnimatedNavigator
               path = "/"
               component = {HypeServicesHomePanel}
               authenticated = {signedIn}
               transitionProps = {hypeBurnTransitionProps}
          />
          
          <HypeBurnAnimatedNavigator
               path = "/authentication"
               component = {HypeServicesAuthenticationPanel}
               authenticated = {signedIn}
               transitionProps = {hypeBurnTransitionProps}
          />
          
          <HypeBurnAnimatedNavigator
               path = "/enterprises"
               component = {HypeServicesEnterprisesPanel}
               authenticated = {signedIn}
               transitionProps = {hypeBurnTransitionProps}
          />
          
          <HypeBurnAnimatedNavigator
               path = "/enterprises/:navigationID"
               component = {HypeServicesEnterpriseViewerPanel}
               authenticated = {signedIn}
               transitionProps = {hypeBurnTransitionProps}
          />
     </>
  );
};

export default App;

