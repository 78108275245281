import * as React from "react";

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";












function HypeSignUpComponent({ sidePanelVisible, hypeSignIn }) {
  const [signUpButtonVisibility, setSignUpButtonVisibility] = useState("hidden");
  
  const [signUpButtonHover, setSignUpButtonHover] = useState(false);
  
  const [signUpProcessIndicatorTitle, setSignUpProcessIndicatorTitle] = useState("Sign Up");
  
  const firstNameSignUpTextFieldRef = useRef();
  
  const middleNameSignUpTextFieldRef = useRef();
  
  const lastNameSignUpTextFieldRef = useRef();
  
  const displayNameSignUpTextFieldRef = useRef();
  
  const usernameSignUpTextFieldRef = useRef();
  
  const emailSignUpTextFieldRef = useRef();
  
  const passwordSignUpTextFieldRef = useRef();
  
  const navigate = useNavigate();
  
  const handleSignUpButtonVerification = (e) => {
          if (!HypeHUDUtilityManager.nullOrWhitespace(displayNameSignUpTextFieldRef.current.value) &&
              !HypeHUDUtilityManager.nullOrWhitespace(usernameSignUpTextFieldRef.current.value) && 
              !HypeHUDUtilityManager.nullOrWhitespace(passwordSignUpTextFieldRef.current.value) &&
              !HypeHUDUtilityManager.nullOrWhitespace(emailSignUpTextFieldRef.current.value) && 
              !HypeHUDUtilityManager.nullOrWhitespace(firstNameSignUpTextFieldRef.current.value) &&
              !HypeHUDUtilityManager.nullOrWhitespace(middleNameSignUpTextFieldRef.current.value) &&
              !HypeHUDUtilityManager.nullOrWhitespace(lastNameSignUpTextFieldRef.current.value)
          )
          {
               setSignUpButtonVisibility("visible");
               
               return;
          }
          
          setSignUpButtonVisibility("hidden");
          
          return;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSignUpButtonVisibility(false);
    
    setSignUpProcessIndicatorTitle("Signing up...");
    
    HypeHUDUtilityManager.setScrollPosition(document, "SignUpModal", 0);
    
    const signedUpHypeUser = await HypeHUDUtilityManager.signUpHypeUser(
      firstNameSignUpTextFieldRef.current.value,
      middleNameSignUpTextFieldRef.current.value,
      lastNameSignUpTextFieldRef.current.value,
      displayNameSignUpTextFieldRef.current.value,
      usernameSignUpTextFieldRef.current.value,
      emailSignUpTextFieldRef.current.value,
      passwordSignUpTextFieldRef.current.value
    );
    
    if (signedUpHypeUser) 
    {
      setSignUpProcessIndicatorTitle("Signed Up!");
      
      hypeSignIn(true);
      
      navigate("/");
    }
    
    setSignUpProcessIndicatorTitle("Try Again!");
    
    setTimeout(() => {
         setSignUpProcessIndicatorTitle("Sign Up");
    }, 2000);
    
    return;
  };
  
  
  
  return (
    <form
         id = "SignUpModal"
         
         className = "hype-scroller"
         
         style = {{
              position : "absolute",
              background : "linear-gradient(to bottom, rgba(185, 91, 252, 0.69) 21%, transparent 127%)",
              height : "469px",
              width : "469px",
              boxShadow : "-10px 10px 7px -5px rgba(21, 21, 21, 0.69), inset 0 0 10px rgba(255, 255, 255, 0.269)",
              borderRadius : "69px 21px 69px 21px",
              overflowY : "auto",
              transition : "margin-left 0.69s ease-in-out",
              marginLeft : sidePanelVisible ? "7.69%" : "15.69%",
              marginTop : "5.69%",
         }}
         
         onSubmit = {handleSubmit}
    >
         <label
              style = {{
                   position : "absolute",
                   fontFamily : "ModeNine",
                   fontWeight : "bold",
                   fontSize : "27.69px",
                   color : "transparent",
                   backgroundClip : "text",
                   background : "linear-gradient(to right, rgba(229, 194, 255, 0.69) 0%, rgba(233, 203, 255, 0.769) 227%)",
                   WebkitBackgroundClip : "text",
                   marginLeft : "33.69%",
                   marginTop : "5%",
              }}
         >
              <HypeTyper speed = {21} >
                   {signUpProcessIndicatorTitle}
              </HypeTyper>
         </label>
         
         <ul style = {{ listStyle : "none", padding : 0 }} >
              <li key = {1} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {firstNameSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "18.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "First name..."
                   >
                        
                   </input>
               </li>
               
               <li key = {2} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {middleNameSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Middle name..."
                   >
                        
                   </input>
               </li>
               
               <li key = {3} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {lastNameSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Last name..."
                   >
                        
                   </input>
               </li>
               
               <li key = {4} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {displayNameSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Display name..."
                   >
                        
                   </input>
               </li>
               
               <li key = {5} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {usernameSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Username..."
                   >
                        
                   </input>
               </li>
               
               <li key = {6} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {emailSignUpTextFieldRef}
                        
                        style = {{
                             position : "relative",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(229, 194, 255, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Email..."
                   >
                        
                   </input>
               </li>
               
               <li key = {7} >
                   <input
                        className = "hype-signup-input"
                        
                        ref = {passwordSignUpTextFieldRef}
                        
                        type = "password"
                        
                        style = {{
                             position : "absolute",
                             borderRadius : "50px",
                             height : "27px",
                             width : "287px",
                             fontFamily : "ModeNine",
                             fontSize : "15.69px",
                             marginLeft : "15%",
                             marginTop : "10.69%",
                             borderLeft : "none",
                             borderTop : "none",
                             color : "rgba(230, 255, 254, 1)",
                             background : "transparent",
                             outline : "none",
                             borderBottom : "solid rgba(229, 194, 255, 1)",
                             borderRight : "solid rgba(229, 194, 255, 1)",
                        }}
                        
                        onChange = {handleSignUpButtonVerification}
                        
                        placeholder = "Password..."
                   >
                        
                   </input>
               </li>
          </ul>
          
          <button
               type = "submit"
               
               style = {{
                    position : "relative",
                    background : "linear-gradient(to right, rgba(194, 142, 232, 0.69) 0%, rgba(233, 203, 255, 0.769) 227%)",
                    color : "rgba(229, 194, 255, 0.769)",
                    visibility : signUpButtonVisibility,
                    fontFamily : "ModeNine",
                    borderRadius : "50px",
                    fontWeight : "bold",
                    transition : "height 0.69s ease-in, width 0.69s ease-in, font-size 0.69s ease-in",
                    fontSize : signUpButtonHover ? "18.69px" : "12.69px",
                    border : "none",
                    boxShadow : signUpButtonHover ? "5px 3px 10px -5px rgba(255, 255, 255, 0.469), inset 0 0 10px rgba(255, 255, 255, 0.469)" : "none",
                    height : signUpButtonHover ? "57px" : "47px",
                    width : signUpButtonHover ? "137px" : "127px",
                    marginLeft : "33%",
                    marginBottom : "5%",
                    marginTop : "21.69%",
                    zIndex : "3"
               }}
               onMouseLeave = {() => {
                    setSignUpButtonHover(false);
                    
                    return;
               }}
               onMouseEnter = {() => {
                    setSignUpButtonHover(true);
                    
                    return;
               }}
               onClick = {() => {
                    return;
               }}
          >
               Sign Up!
          </button>
      </form>
  );
}

export default HypeSignUpComponent;