import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import LegendaryHypeServicesLogo from "../HUDContent/HUDHypeUI/LegendaryHypeServicesLogo.png";

import { useHypeAuth } from "../HUDComponents/HypeAuthProvider.js";

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeSignUpComponent from "../HUDComponents/HypeSignUpComponent.js";

import HypeSignInComponent from "../HUDComponents/HypeSignInComponent.js";

import HypeSidePanel from "../HUDComponents/HypeSidePanel.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











function HypeServicesAuthenticationPanel()
{
     const [hypeServicesTitleIlluminationLoaded, setHypeServicesTitleIlluminationLoaded] = useState(false);
     
     const [hypeServicesTitleLoaded, setHypeServicesTitleLoaded] = useState(false);
     
     const [hypeServicesLogoLoaded, setHypeServicesLogoLoaded] = useState(false);
     
     const [sidePanelVisible, setSidePanelVisible] = useState(true);
     
     const { signedIn, hypeSignIn, hypeSignOut } = useHypeAuth();
     
     const sidePanelRef = useRef();
     
     
     
     
     
     const handleMouseMove = (e) => {
          if (sidePanelRef.current !== null && sidePanelRef.current !== undefined)
          {
               setSidePanelVisible(HypeHUDUtilityManager.mouseNearComponent(sidePanelRef, 269, e));
          }
          
          return;
     };
     
     const handleButtonClick = (buttonNavigationID) => {
          return;
     };
     
     const buttons = [
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonIcon.png"), 
            navigationID : "/" 
          },
     ];
     
     const subHeaderButton = {
          hoverImage : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDActivatedIcon.png"),
          image : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDIcon.png"), 
          navigationID : "/authentication",
     };
     
     
     
     
     
     
     
     
     
     
     useEffect(() => {
          const hypeServicesTitleIlluminationLoading = setTimeout(() => {
               setHypeServicesTitleIlluminationLoaded(true);
          }, 2569);
          
          const hypeServicesTitleLoading = setTimeout(() => {
               setHypeServicesTitleLoaded(true);
          }, 699);
          
          const hypeServicesLogoLoading = setTimeout(() => {
               setHypeServicesLogoLoaded(true);
          }, 1169.69);
          
          
          
          return () => {
               clearTimeout(hypeServicesTitleIlluminationLoading);
               
               clearTimeout(hypeServicesTitleLoading);
               
               clearTimeout(hypeServicesLogoLoading);
          };
     }, []);
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          <style>
                  {`
                    body {
                      background : linear-gradient(to bottom, #271d29 80%, #ff00ff 287%);
                      margin : 0;
                      padding : 0;
                      min-height : 100vh;
                    }
                  `}
          </style>
          
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    background : "transparent",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    bottom : "0%"
               }}
               onMouseMove = {handleMouseMove}
          >
               <div
                    style = {{
                         position : "absolute",
                         background : "linear-gradient(to bottom, rgba(255, 125, 251, 0.69) 21%, transparent 127%)",
                         height : "469px",
                         width : "469px",
                         boxShadow : "10px 10px 7px -5px rgba(21, 21, 21, 0.69), inset 0 0 10px rgba(255, 255, 255, 0.269)",
                         borderRadius : "57px 8px 57px 8px",
                         transition : "margin-left 0.69s ease-in-out",
                         marginLeft : sidePanelVisible ? "21%" : "9.69%",
                         marginTop : "5.69%",
                    }}
               >
                    <HypeSignInComponent sidePanelVisible = {sidePanelVisible} hypeSignIn = {hypeSignIn} />
               </div>
               
               
               
               
               
               <div
                    style = {{
                         position : "absolute",
                         background : "linear-gradient(to bottom, rgba(255, 125, 251, 0.69) 21%, transparent 127%)",
                         height : "469px",
                         width : "469px",
                         boxShadow : "10px 10px 7px -5px rgba(21, 21, 21, 0.69), inset 0 0 10px rgba(255, 255, 255, 0.269)",
                         borderRadius : "8px 57px 8px 57px",
                         transition : "margin-left 0.69s ease-in-out",
                         marginLeft : sidePanelVisible ? "61%" : "51.69%",
                         marginTop : "5.69%",
                    }}
               >
                    <HypeSignUpComponent sidePanelVisible = {sidePanelVisible} hypeSignIn = {hypeSignIn} />
               </div>
               
               <HypeSidePanel
                    ref = {sidePanelRef}
                    headerLogo = {LegendaryHypeServicesLogo}
                    header = "Authentication"
                    subHeader = "Sign In"
                    subHeaderButton = {subHeaderButton}
                    buttons = {buttons}
                    onButtonClick = {handleButtonClick}
                    pageIdentifier = {true}
                    setVisible = {setSidePanelVisible}
                    visible = {sidePanelVisible}
                    authenticated = {true}
                    subHeaderButtonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    subHeaderButtonConfigurationStyle = {{
                         height : "127px",
                         width : "37%",
                         marginLeft : "57px",
                         marginTop : "47px",
                         background : "transparent",
                         fontFamily : "ModeNine",
                         cursor : "pointer",
                    }}
                    sidePanelConfigurationStyle = {{
                         backgroundColor : "rgba(29, 23, 31, 0.569)",
                         width : "187.69px",
                         zIndex : "1699"
                    }}
                    subHeaderConfigurationStyle = {{
                         fontFamily : "ModeNine",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.269)",
                         background : "rgba(15, 15, 15, 0.469)",
                         height : "169px"
                    }}
                    headerConfigurationStyle = {{
                         marginBottom : "5%",
                         marginLeft : "3%",
                    }}
                    buttonListConfigurationStyle = {{
                         marginLeft : "12.69%",
                         marginTop : "7%",
                         height : "calc(69% - 115.69px)",
                    }}
                    buttonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    buttonConfigurationStyle = {{
                         backgroundColor : "transparent",
                         fontFamily : "ModeNine",
                         borderRadius : "8px",
                         height : "145px",
                         width : "103.69px",
                         cursor : "pointer",
                         marginBottom : "15%",
                         marginLeft : "14.69%",
                         marginTop : "5%",
                    }}
               >
               
               </HypeSidePanel>
          </div>
          </>
     );
}

export default HypeServicesAuthenticationPanel;