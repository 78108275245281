import * as React from 'react';

import { useState, useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import LegendaryHypeServicesLogo from "../HUDContent/HUDHypeUI/LegendaryHypeServicesLogo.png";

import HypeServicesEnterpriseViewerPanel from "../HUDPages/HypeServicesEnterpriseViewerPanel.js";

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeMediaShowcaseList from "../HUDComponents/MediaShowcaseList.js";

import HypeSidePanel from "../HUDComponents/HypeSidePanel.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











function HypeServicesEnterprisesPanel({ signedIn })
{
     const [sidePanelVisible, setSidePanelVisible] = useState(true);
     
     const sidePanelRef = useRef();
     
     const navigate = useNavigate();
     
     const { navigationID, title: routeTitle } = useParams();
     
     const handleMouseMove = (e) => {
          if (sidePanelRef.current !== null && sidePanelRef.current !== undefined)
          {
               setSidePanelVisible(HypeHUDUtilityManager.mouseNearComponent(sidePanelRef, 269, e));
          }
     };
     
     const handleButtonClick = (buttonNavigationID) => {
          return;
     };
     
     const handleEnterpriseItemClicked = (item) => {
          const { navigationID } = item;
          
          navigate(`/enterprises/${navigationID}`, { state: { enterpriseItemData: item } });
     };
     
     const buttons = [
          { 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHUDMultiverseHomeButtonIcon.png"), 
            navigationID : "/" 
          },
          /*{ 
            hoverImage : require("../HUDContent/HUDHypeUI/HypeTrainHypeServiceConfigurationHUDButtonActivatedIcon.png"), 
            image : require("../HUDContent/HUDHypeUI/HypeTrainHypeServiceConfigurationHUDButtonIcon.png"), 
            navigationID : "/servicemanagement" 
          },*/
     ];
     
     const subHeaderButton = {
          hoverImage : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDActivatedIcon.png"),
          image : require("../HUDContent/HUDHypeUI/LegendaryHypeTrainUniversalProfileAuthenicationRequiredButtonHUDIcon.png"), 
          navigationID : "/authentication",
     };
     
     const yourHypeEnterprises = [
          { 
               hoverSource : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterpriseActivatedIcon.png"),
               source : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterpriseIcon.png"),
               type : "image",
               navigationID : "encryptabyte",
               title : "Encryptabyte LLC"
          },
          { 
               hoverSource : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterpriseActivatedIcon.png"),
               source : require("../HUDContent/HUDHypeUI/HypeTrainHUDEnterpriseIcon.png"),
               type : "image",
               navigationID : "generalizedrobotics",
               title : "Generalized Robotics Corp",
          },
     ];
     
     
     
     
     
     
     
     
     
     
     
     return (
          <>
          <style>
                  {`
                    body {
                      background : linear-gradient(to bottom, #271d29 80%, #ff00ff 287%);
                      margin : 0;
                      padding : 0;
                      min-height : 100vh;
                    }
                  `}
          </style>
          
          <div
               style = {{
                    position : "relative",
                    display : "flex",
                    background : "transparent",
                    minHeight : "100vh",
                    minWidth : "100vw",
                    height : "calc(100%)",
                    width : "calc(100%)",
                    bottom : "0%"
               }}
               onMouseMove = {handleMouseMove}
          >
               <HypeMediaShowcaseList
                    gridConfigurationStyle = {{
                         background : "rgba(0, 0, 0, 0.269)",
                         flexDirection: "column",
                         width : "176px",
                         boxShadow : sidePanelVisible ? "inset 0 0 10px rgba(0, 0, 0, 0.469)" : "0 0 5px rgba(0, 0, 0, 0.469)",
                         transition : "box-shadow 1.769s ease-in-out, margin-left 0.69s ease-in-out",
                         marginLeft : sidePanelVisible ? "187.69px" : "1%",
                         zIndex : "2"
                    }}
                    
                    listHeaderTitleConfigurationStyle = {{
                         position : "absolute",
                         fontFamily : "ModeNine",
                         fontWeight : "bold",
                         fontSize : "14.69px",
                         color : "#a3a3a3",
                         transition : "margin-left 0.7169s ease-in-out",
                         marginLeft : sidePanelVisible ? "1.269%" : "0.769%",
                         marginTop : "1%",
                         zIndex : "1",
                    }}
                    
                    titleConfigurationStyle = {{
                         color : "white",
                         marginTop : "69%",
                    }}
                    
                    mediaConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    cellConfigurationStyle = {{
                         background : "transparent",
                         height : "127px",
                         width : "127px",
                         marginTop : "3%",
                    }}
                    
                    overlayConfigurationStyle = {{
                         background : "transparent"
                    }}
                    
                    onItemClicked = {handleEnterpriseItemClicked}
                    
                    showcaseItems = {yourHypeEnterprises}
                    
                    listHeaderTitle = "Your Enterprises"
                    
                    listHeaderVisible = {true}
                    
                    startColor = {[0, 0, 0, 1]}
                    trailColor = {[0, 0, 0, 1]}
                    startHoverColor = {[0, 0, 0, 1]}
                    trailHoverColor = {[0, 0, 0, 1]}
               >
                    
               </HypeMediaShowcaseList>
               
               <HypeSidePanel
                    ref = {sidePanelRef}
                    
                    headerLogo = {LegendaryHypeServicesLogo}
                    
                    header = "Enterprises"
                    
                    subHeader = "Sign In"
                    
                    subHeaderButton = {subHeaderButton}
                    
                    buttons = {buttons}
                    
                    onButtonClick = {handleButtonClick}
                    
                    pageIdentifier = {true}
                    
                    setVisible = {setSidePanelVisible}
                    
                    visible = {sidePanelVisible}
                    
                    authenticated = {true}
                    
                    subHeaderButtonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    subHeaderButtonConfigurationStyle = {{
                         height : "127px",
                         width : "37%",
                         marginLeft : "57px",
                         marginTop : "47px",
                         background : "transparent",
                         fontFamily : "ModeNine",
                         cursor : "pointer",
                    }}
                    
                    sidePanelConfigurationStyle = {{
                         backgroundColor : "rgba(29, 23, 31, 0.569)",
                         width : "187.69px",
                         zIndex : "1699"
                    }}
                    
                    subHeaderConfigurationStyle = {{
                         fontFamily : "ModeNine",
                         boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.269)",
                         background : "rgba(15, 15, 15, 0.469)",
                         height : "169px"
                    }}
                    
                    headerConfigurationStyle = {{
                         marginBottom : "5%",
                         marginLeft : "3%",
                    }}
                    
                    buttonListConfigurationStyle = {{
                         marginLeft : "12.69%",
                         marginTop : "7%",
                         height : "calc(69% - 115.69px)",
                    }}
                    
                    buttonImageConfigurationStyle = {{
                         objectFit : "scale-down"
                    }}
                    
                    buttonConfigurationStyle = {{
                         backgroundColor : "transparent",
                         fontFamily : "ModeNine",
                         borderRadius : "8px",
                         height : "145px",
                         width : "103.69px",
                         cursor : "pointer",
                         marginBottom : "15%",
                         marginLeft : "14.69%",
                         marginTop : "5%",
                    }}
                    
                    //footer = {true}
                    
                    footerButtonHoverIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonActivatedIcon.png")}
                    
                    footerButtonIcon = {require("../HUDContent/HUDHypeUI/HypeTrainHUDLogOutButtonIcon.png")}
               >
               
               </HypeSidePanel>
          </div>
          </>
     );
}

export default HypeServicesEnterprisesPanel;