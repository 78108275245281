import React, { useState, useEffect, useCallback } from 'react';











function HypeTyper({ speed, children }) {
  const [displayedText, setDisplayedText] = useState('');
  
  const [typingIndex, setTypingIndex] = useState(0);
  
  useEffect(() => {
       let typerMounted = true;
       
       const textToType = children.toString();
       
       const typingTimer = setTimeout(() => {
            if (!typerMounted) { return; }
            
            setDisplayedText(textToType.substring(0, typingIndex + 1));
            
            if (typingIndex + 1 >= textToType.length) 
            {
                 clearTimeout(typingTimer);
                 
                 return;
            }
            
            setTypingIndex((pIndex) => pIndex + 1);
            
            return;
       }, speed);
       
       if (typingIndex >= textToType.length) 
       {
            clearTimeout(typingTimer);
       }
       
       return () => {
            typerMounted = false;
            
            clearTimeout(typingTimer);
       };
  }, [typingIndex, children, speed]);
  
  useEffect(() => {
       setTypingIndex(0);
  }, [children]);

  return <>{displayedText}</>;
}

export default HypeTyper;