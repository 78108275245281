import React from 'react';

import { Routes, Route, useLocation } from 'react-router-dom';

import HypeBurnTransition from '../HUDComponents/HypeBurnTransition';











const HypeBurnAnimatedNavigator = ({ component : Component, authenticated, transitionProps, ...rest }) => {
  const location = useLocation();
  
  
  
  
  
  
  
  
  
  
  return (
    <Routes>
      <Route
        {...rest}
        
        element={
          <>
            <HypeBurnTransition {...transitionProps} key = {location.key} />
            
            <Component 
                 signedIn = {authenticated}
            > 
                 
            </Component>
          </>
        }
      />
    </Routes>
  );
};

export default HypeBurnAnimatedNavigator;