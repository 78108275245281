import React from 'react';

import { useEffect, useState, useRef } from "react";

import HypeHUDUtilityManager from "../HUDManagers/HypeHUDUtilityManager.js";

import HypeScrollerObserver from "../HUDComponents/HypeScrollerObserver.js";

import HypeNavigator from "../HUDComponents/HypeNavigationComponent.js";

import HypeLogoComponent from "../HUDComponents/HypeLogoComponent.js";

import HypeTyper from "../HUDComponents/HypeTyper.js";











const HypeSidePanel = React.forwardRef(({ 
  headerLogo,
  header, 
  subHeader,
  subHeaderButton,
  footer,
  footerButtonHoverIcon,
  footerButtonIcon,
  buttons, 
  onFooterButtonClick,
  onButtonClick,
  onButtonMouseLeave,
  onButtonMouseEnter, 
  sidePanelConfigurationStyle, 
  subHeaderConfigurationStyle,
  headerConfigurationStyle,
  buttonListContainerConfigurationStyle,
  subHeaderButtonConfigurationStyle, 
  buttonListConfigurationStyle,
  buttonConfigurationStyle,
  subHeaderButtonImageConfigurationStyle,
  buttonImageConfigurationStyle,
  pageIdentifier,
  authenticated,
  setVisible,
  visible
}, sidePanelRef) => {
  const [currentButton, setCurrentButton] = useState(null);
  
  const sidePanelStyle = {
    position : "fixed",
    bottom : "0",
    left : visible ? "0" : "-176px",
    top : "0",
    backgroundColor : "#333",
    borderRadius : "0px 21px 21px 0px",
    transition : "left 0.69s ease-in-out, box-shadow 2.569s ease-in-out, elevation 2.569s ease-in-out",
    transitionDelay : "box-shadow 0.69s, elevation 0.69s",
    paddingTop : "20px",
    elevation : "-7",
    boxShadow : "inset 0 -2px 10px rgba(0, 0, 0, 0.5469)",
    ...sidePanelConfigurationStyle
  };

  const headerStyle = {
    color : '#fff',
    background : "transparent",
    ...headerConfigurationStyle
  };
  
  const subHeaderStyle = {
    textAlign : 'center',
    color : '#bbb',
    background : "transparent",
    fontSize : '16px',
    marginBottom : '15px',
    marginTop : "58.69px",
    transition : "width 0.69s ease-in, border-radius 0.69s ease-in-out",
    transitionDelay : "0.269s",
    borderRadius : visible ? "0px 21px 0px 21px" : "0px 21px 21px 0px",
    width : visible ? "100%" : "87%",
    ...subHeaderConfigurationStyle
  };
  
  const subHeaderButtonStyle = {
    display: 'block',
    width: '100%',
    padding: '8px',
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#555',
    border: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    marginBottom: '8px',
    ...subHeaderButtonConfigurationStyle
  };
  
  const buttonListStyle = {
    listStyle: 'none',
    backgroundColor : "rgba(21, 21, 21, 0.569)",
    transition : "box-shadow 2.569s ease-in-out, elevation 2.569s ease-in-out",
    transitionDelay : '0.69s',
    elevation : "-7",
    boxShadow : 'inset 0 0 10px rgba(0, 0, 0, 0.469)',
    height : "calc(69% - 147px)",
    width : "calc(137px)",
    borderRadius : "21px",
    marginLeft : "8.69%",
    overflowX : "hidden",
    overflowY : "auto",
    padding: '0',
    ...buttonListConfigurationStyle
  };
  
  const subHeaderButtonImageStyle = {
    objectFit : "cover",
    height : "calc(100%)",
    width : "calc(100%)",
    ...subHeaderButtonImageConfigurationStyle
  }
  
  const buttonImageStyle = {
    objectFit : "cover",
    height : "calc(100%)",
    width : "calc(100%)",
    ...buttonImageConfigurationStyle
  };

  const buttonStyle = {
    display: 'block',
    textAlign: 'center',
    textDecoration: 'none',
    border: 'none',
    color: '#fff',
    transition: 'background-color 0.3s',
    ...buttonConfigurationStyle
  };
  
  
  
  
  
  useEffect(() => {
       if (setVisible !== null && setVisible !== undefined)
       {
            setVisible(true);
            
            return () => {
                 setVisible(false);
            };
       }
       
       return () => { };
  }, []);
  
  useEffect(() => {
       HypeScrollerObserver(document, [69, 69, 69, 1], [224, 133, 255, 1], [171, 157, 176, 1], [242, 204, 255, 1]);
       
       return () => { };
  }, [document]);
  
  
  
  
  
  
  
  
  
  
  
  return (
    <div 
      style = {sidePanelStyle}
      ref = {sidePanelRef}
    >
      {pageIdentifier && (
         <HypeLogoComponent 
                logo = {headerLogo} 
                mottoVisible = {true} 
                mottoConfigurationSettings = {{
                     marginLeft : "251.69px",
                     marginTop : "21px",
                     overflow : "hidden",
                     wordWrap : "break-word",
                     borderRadius : "8px",
                     height : "51.69px",
                     transition : "width 0.69s ease-in-out",
                     width : "247.69px",
                     fontFamily : "ModeNine",
                     WebkitTextStroke : "none",
                     color : "white"
                }}
                logoConfigurationSettings = {{
                     marginLeft : "194px",
                     marginTop : "3px",
                }}
         >
                {header}
         </HypeLogoComponent>
      ) || (
         <div style = {headerStyle} >
           <HypeLogoComponent 
                logo = {headerLogo} 
                mottoVisible = {true} 
                mottoConfigurationSettings = {{
                     marginLeft : "64.69px",
                     marginTop : "21px",
                     overflow : "hidden",
                     wordWrap : "break-word",
                     borderRadius : "8px",
                     height : "51.69px",
                     transition : "width 0.69s ease-in-out",
                     width : visible ? "100.69px" : "69px",
                     fontFamily : "ModeNine",
                     WebkitTextStroke : "none",
                     color : "white"
                }}
                logoConfigurationSettings = {{
                     marginLeft : "7px",
                     marginTop : "3px",
                }}
           >
                {header}
           </HypeLogoComponent>
         </div>
      )}
      
      {subHeader && (
        <div style = {subHeaderStyle}>
          {subHeaderButton && (
            <HypeNavigator>
                 {(navigateTo) => (
                      <button
                           style = {{
                                ...subHeaderButtonStyle,
                                transform : `scale(${currentButton === "headerButton" ? 1.05 : 1})`,
                           }}
                           onMouseLeave = {() => {
                                setCurrentButton(null);
                                
                                HypeHUDUtilityManager.executeAction(onButtonMouseLeave);
                           }}
                           onMouseEnter = {() => {
                                setCurrentButton("headerButton");
                                
                                HypeHUDUtilityManager.executeAction(onButtonMouseEnter);
                           }}
                           onClick={() => {
                                HypeHUDUtilityManager.executeAction(onButtonClick, subHeaderButton.navigationID);
                                
                                navigateTo(subHeaderButton.navigationID);
                           }}
                      >
                           {subHeaderButton.image && (
                                <img
                                     src = {currentButton == "headerButton" ? subHeaderButton.hoverImage : subHeaderButton.image}
                                     alt = {subHeaderButton.label}
                                     style = {subHeaderButtonImageStyle}
                                />
                           )}
                           
                           {subHeaderButton.label && !subHeaderButton.image && <span><HypeTyper speed = {21} >{subHeaderButton.label}</HypeTyper></span>}
                           
                           {subHeaderButton.label && subHeaderButton.image && <span><HypeTyper speed = {21} >{subHeaderButton.label}</HypeTyper></span>}
                      </button>
                 )}
            </HypeNavigator>
          )}
          {subHeader}
        </div>
      )}
      
      {authenticated && (
           <ul className = "hype-scroller" style = {buttonListStyle} >
                {buttons.map((button, index) => (
                     <li key = {index} >
                          <HypeNavigator>
                               {(navigateTo) => (
                                   <button
                                        style = {{
                                             ...buttonStyle,
                                             transform : `scale(${currentButton === index ? 1.05 : 1})`,
                                        }}
                                        onMouseLeave = {() => {
                                             setCurrentButton(null);
                                             
                                             HypeHUDUtilityManager.executeAction(onButtonMouseLeave);
                                        }}
                                        onMouseEnter = {() => {
                                             setCurrentButton(index);
                                             
                                             HypeHUDUtilityManager.executeAction(onButtonMouseEnter);
                                        }}
                                        onClick = {() => {
                                             HypeHUDUtilityManager.executeAction(onButtonClick, button.navigationID);
                                             
                                             navigateTo(button.navigationID);
                                        }}
                                   >
                                         {button.image && (
                                              <img
                                                   src = {index == currentButton ? button.hoverImage : button.image}
                                                   alt = {button.label}
                                                   style = {buttonImageStyle}
                                              />
                                         )}
                                         
                                         {button.label && !button.image && <span><HypeTyper speed = {21} >{button.label}</HypeTyper></span>}
                                         
                                         {button.label && button.image && <span><HypeTyper speed = {21} >{button.label}</HypeTyper></span>}
                                    </button>
                               )}
                          </HypeNavigator>
                     </li>
                ))}
           </ul>
      )}
      
      
      
      {authenticated && footer && (
           <div
                style = {{
                     position : "absolute",
                     boxShadow : "inset 0 0 10px rgba(0, 0, 0, 0.769)",
                     background : "rgba(0, 0, 0, 0.269)",
                     borderRadius : "8px 8px 21px 21px",
                     height : "69px",
                     transition : "width 0.69s ease-in-out",
                     width : visible ? "100%" : "87%",
                     bottom : "0px",
                }}
           >
                <img
                     style = {{
                          position : "absolute",
                          height : "47px",
                          width : "127px",
                          scale : currentButton == "footerButton" ? "1.069" : "1",
                          transition : "scale 0.69s ease-in-out",
                          borderRadius : "8px",
                          marginLeft : "27px",
                          marginTop : "7px",
                     }}
                     
                     onMouseLeave = {() => {
                          setCurrentButton(null);
                          
                          HypeHUDUtilityManager.executeAction(onButtonMouseLeave);
                     }}
                     
                     onMouseEnter = {() => {
                          setCurrentButton("footerButton");
                          
                          HypeHUDUtilityManager.executeAction(onButtonMouseEnter);
                     }}
                     
                     onClick={() => {
                          HypeHUDUtilityManager.executeAction(onFooterButtonClick, subHeaderButton.navigationID);
                     }}
                     
                     src = {currentButton == "footerButton" ? footerButtonHoverIcon : footerButtonIcon}
                >
                     
                </img>
           </div>
      )}
    </div>
  );
});

export default HypeSidePanel;
