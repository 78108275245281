function HypeScrollerObserver(document, startHypeColor, trailHypeColor, startHoverHypeColor, trailHoverHypeColor) {
  const hypeScroller = document.querySelector('.hype-scroller');
  
  if (hypeScroller === undefined || hypeScroller === null) { return; }
  
  let verticalOrientation = false;
  
  if (hypeScroller.scrollHeight > hypeScroller.clientHeight) 
  {
    verticalOrientation = true;
  }
  
  let mouseDown = false;
  
  let hovered = false;
  
  const handleHypeScroll = () => {
    const scrollPosition = verticalOrientation ? hypeScroller.scrollTop : hypeScroller.scrollLeft;
    
    const maxScroll = verticalOrientation ? hypeScroller.scrollHeight - hypeScroller.clientHeight : hypeScroller.scrollWidth - hypeScroller.clientWidth;
    
    const atScrollEnd = scrollPosition === maxScroll;
    
    const interpolatedColor = interpolateColor(
      (hovered && mouseDown) ? startHoverHypeColor : startHypeColor,
      (hovered && mouseDown) ? trailHoverHypeColor : trailHypeColor,
      scrollPosition / maxScroll
    );
    
    const rgbaColor = `rgba(${interpolatedColor.join(',')})`;
    
    hypeScroller.style.setProperty('--scrollbar-thumb-color', rgbaColor);
    
    if (atScrollEnd) 
    {
      hypeScroller.classList.add('end-scroll');
      
      return;
    }
    
    hypeScroller.classList.remove('end-scroll');
    
    return
  };

  const handleMouseMove = (event) => {
    const { top, bottom, left, right } = hypeScroller.getBoundingClientRect();
    
    hovered = 
      (verticalOrientation && event.clientY >= top && event.clientY <= bottom) ||
      (!verticalOrientation && event.clientX >= left && event.clientX <= right);
    
    handleHypeScroll();
    
    return;
  };
  
  const handleMouseLeave = () => {
    hypeScroller.classList.remove('thumb-hovered');
    
    return;
  };
  
  const handleMouseEnter = () => {
    hypeScroller.classList.add('thumb-hovered');
    
    return;
  }
  
  const handleMouseDown = () => {
    mouseDown = true;
    
    handleHypeScroll();
    
    return;
  };

  const handleMouseUp = () => {
    mouseDown = false;
    
    handleHypeScroll();
    
    return;
  };
  
  hypeScroller.addEventListener('scroll', handleHypeScroll);
  
  hypeScroller.addEventListener('mouseenter', handleMouseEnter);
  
  hypeScroller.addEventListener('mouseleave', handleMouseLeave);
  
  hypeScroller.addEventListener('mousemove', handleMouseMove);
  
  hypeScroller.addEventListener('mousedown', handleMouseDown);
  
  hypeScroller.addEventListener('mouseup', handleMouseUp);
  
  return () => {
    hypeScroller.removeEventListener('scroll', handleHypeScroll);
    
    hypeScroller.removeEventListener('mouseenter', handleMouseEnter);
    
    hypeScroller.removeEventListener('mouseleave', handleMouseLeave);
    
    hypeScroller.removeEventListener('mousemove', handleMouseMove);
    
    hypeScroller.removeEventListener('mousedown', handleMouseDown);
    
    hypeScroller.removeEventListener('mouseup', handleMouseUp);
  };
}

function interpolateColor(color1, color2, factor) {
  return color1.map((channel, index) => {
    const blendedChannel = Math.round(channel + factor * (color2[index] - channel));
    
    return Math.min(255, Math.max(0, blendedChannel));
  });
}

export default HypeScrollerObserver;
